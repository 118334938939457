import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import {AuthService, AlertifyService} from 'core';

@Component({
  selector: 'or-alerts-manager',
  templateUrl: './alerts-manager.component.html',
  styleUrls: ['./alerts-manager.component.scss']
})
export class AlertsManagerComponent implements OnInit {
  @Input() public set project(data) { this._project = data; }
  @Input() public set tasks(data) { this.listTasks = data; }
  @Input() public set rule(data) {
    this._rule = data;
    this.loadData();
  }

  @Output() conditionChanged = new EventEmitter();
  @ViewChild('ddScope', {static: false}) ddScope;

  _project;
  _projectTeam:any = [];
  anyWithoutSlackUsername = false;
  _rule;
  integerPattern = { 9: { pattern: new RegExp('^[0-9]*$'), optional: true } };

  scopeTypes = [{label: 'Project', type: 'Project', value: ''}, {label: 'Vendor', type: 'Vendor', value: '', vendors: [{id: 'A', label: 'CINT'}, {id: 'B', label: 'Prodege'}]}]
  metricTypes = ['Completes', 'Starts', 'Nav over quotas', 'Manual QCs'];
  alertMethods = ['Email', 'SMS text message', 'Slack'];
  operators = [{label: 'At', value: '='}, {label: 'Above', value: '>'}, {label: 'Below', value: '<'}];
  taskAlertRecipients = ['Me', 'PM', 'Assigned To'];

  selectedScope = 'Select metric type';
  selectedTaskType = 'Select Type';
  selectedMetric = 'Select metric';
  selectedMetricNumber: any = {label: '0', value: 0, suffix: ''};
  selectedOperator = 'Select';
  selectedAlertMethod = 'Select method';
  selectedAlertRecipient = 'Select user';
  selectedTaskAlertRecipient = 'Someone';
  selectedTask = 'Select Task';

  selectedScheduledDate: any = {label: 'Date', value: new Date()};
  selectedScheduledTime: any = {label: 'Time', value: new Date()};

  totalChecked = 0;

  addingEmail = false;
  newRecipient = '';
  canAddNewRecipient = false;
  listRecipients: any[] = [{selectedValue: 'Select user', type: '', selected:false}];
  listTasks: any[] = [];

  emailValidator = new FormControl('', [Validators.required, Validators.email]);

  constructor(
    private auth: AuthService,
    private elRef: ElementRef,
    private alertify: AlertifyService) { }

  ngOnInit(): void { }

  loadData() {
    this._projectTeam = this._project?.projectTeam;

    // Get all Vendors from Project
    this.scopeTypes.find(x => x.type == 'Vendor').vendors = this._project?.projectSegments[0].projectSurveyPartners.map(vendor => ({ id: vendor.partnerId, label: vendor.partnerName }));

    if (this._rule.id || this._rule.copy) {

      this.selectedScope = this._rule.scopeType;

      if (this.selectedScope === 'Vendor') {
        let label = this.scopeTypes.find(x => x.type == 'Vendor').vendors.find(x => x.id.toLowerCase() === this._rule.scopeId)?.label;
        this.selectedScope = label;
      }

      this.selectedMetric = this._rule.rules[0].metricType;
      this.selectedMetricNumber = {label: this._rule.rules[0].value, value: this._rule.rules[0].value, suffix: ''};

      switch (this.selectedMetric) {
        case 'IR':
        case 'DOR':
        case 'CID Blocks':
        case 'Manual QCs':
        case 'Dupes':
          this.selectedMetricNumber.suffix = '%';
          break;
        default:
          break;
      }

      this.selectedAlertMethod = this._rule.ruleActions[0].alertMethod;

      this.listRecipients = this._rule.ruleActions[0].alertRecipientType?.split(',').map(item => ({ value: item, type: item.includes('@') ? 'email' : null, selected: true }));

      const defaultOptions = this.selectedScope === 'Project'
        ? [{ label: 'Me' }, { label: 'Project Manager' }, { label: 'Secondary PM' }]
        : [{ label: 'Me' }, { label: 'Project Manager' }, { label: 'Secondary PM' }, { label: 'Vendor' }];

        // merge the default options with the recipients from DB
      this.listRecipients = this._rule.ruleActions[0].alertRecipientType?.split(',').map((item) => {
        const type = item.includes('@') ? 'email' : null;
        const options = [...defaultOptions, { label: item }].filter((option, index, self) => self.findIndex((o) => o.label === option.label) === index);
        return { value: item, type, selected: true, options };
      }) || [];

      this.handleSelectedRecipients(false);

      this.selectedOperator = this.operators.find(x => x.value ===  this._rule.rules[0].operator).label;

      if (this._rule.ruleType == 'scheduled') {
        let scheduledDate = new Date(this._rule.ruleActions[0].scheduledTime);
        scheduledDate.setHours(scheduledDate.getHours() - scheduledDate.getTimezoneOffset() / 60);
        let scheduledDateLabel = scheduledDate.toLocaleDateString(['en-US'], {year: 'numeric', month: 'numeric', day: 'numeric'});
        this.selectedScheduledDate = {label: scheduledDateLabel, value: scheduledDate};

        let scheduledTimeLabel = scheduledDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: true});
        this.selectedScheduledTime = {label: scheduledTimeLabel, value: scheduledDate};
      }

      if (this._rule.ruleType == 'task') {
        this.selectedTaskType = this._rule.scopeType;
        let task = 'Select Task';
        let item = this.listTasks.find(x => x.id === this._rule.scopeId);
        if (item === undefined || item === null) return;
        task = item.stage + ' | ' + item.name;
        this.selectedTask = task;
        this.selectedTaskAlertRecipient = this._rule.ruleActions[0].alertRecipientType;

        let scheduledDate = new Date(this._rule.ruleActions[0].scheduledTime);
        scheduledDate.setHours(scheduledDate.getHours() - scheduledDate.getTimezoneOffset() / 60);
        let scheduledDateLabel = scheduledDate.toLocaleDateString(['en-US'], {year: 'numeric', month: 'numeric', day: 'numeric'});
        this.selectedScheduledDate = {label: scheduledDateLabel, value: scheduledDate};

        let scheduledTimeLabel = scheduledDate.toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: true});
        this.selectedScheduledTime = {label: scheduledTimeLabel, value: scheduledDate};
      }
    }
  }

  applyScope(type, newValue, vendor = null) {
    this.selectedScope = (vendor === null ? newValue.label : vendor.label);

    if (type == 'Project') {
      newValue.value = this._project.id;

      this.removeVendorFromRecipients();

      if (this.selectedAlertRecipient === 'Vendor') this.selectedAlertRecipient = 'Select user';

    } else {
      newValue.value = vendor.id;
      this.updateRecipientsBasedOnScope();
    }

    this.ddScope.hide();
    this.handleSelectedRecipients();
    this.conditionChanged.emit({condition: 'scope', newValue});
  }

  applyTaskType(type) {
    this.selectedTaskType = type;
    this.selectedTask = 'Select Task';
    this.taskAlertRecipients = ['Me', 'PM', 'Assigned To'];
    this.ddScope.hide();
  }

  applySelectedTask(task) {
    this.selectedTask = task.stage === undefined
      ? task.name
      : task.stage + ' | ' + task.name;

    let newValue = {value: task.id, label: this.selectedTaskType}
    this.ddScope.hide();
    this.conditionChanged.emit({condition: 'scope', newValue});
  }

  applyMetric(newValue) {
    this.selectedMetric = newValue;

    switch (this.selectedMetric) {
      case 'IR':
      case 'DOR':
      case 'CID Blocks':
      case 'Manual QCs':
      case 'Dupes':
        this.selectedMetricNumber.suffix = '%';
        break;
      default:
        this.selectedMetricNumber.suffix = '';
        break;
    }

    if (this.selectedMetric === 'Completes') {
      this.operators = [{label: 'At', value: '='}, {label: 'Above', value: '>'}, {label: 'Below', value: '<'}];
      this.conditionChanged.emit({condition: 'operator', newValue: {value: '='} });
    } else {
      this.operators = [{label: 'Above', value: '>'}, {label: 'Below', value: '<'}];
      this.conditionChanged.emit({condition: 'operator', newValue: {value: null} });
    }

    this.selectedOperator = 'Select';
    this.conditionChanged.emit({condition: 'metric', newValue});
  }

  applyMetricNumber() {
    let newValue = this.selectedMetricNumber;
    newValue.value = newValue.label;
    this.conditionChanged.emit({condition: 'metric-number', newValue});
  }

  applyScheduledDate() {
    if (!this.selectedScheduledDate.value || !this.selectedScheduledTime.value) {
      this.conditionChanged.emit({condition: 'scheduled-date', newValue: {value: null}});
      return;
    }

    this.selectedScheduledDate.label = new Date(this.selectedScheduledDate.value).toLocaleDateString(['en-US'], {year: 'numeric', month: 'numeric', day: 'numeric'});
    let dateString = this.selectedScheduledDate.label;

    this.selectedScheduledTime.label = new Date(this.selectedScheduledTime.value).toLocaleTimeString([], {hour: '2-digit', minute: '2-digit', hour12: true});
    let timeString = this.selectedScheduledTime.label;

    let newValue = { value: new Date(dateString + ' ' + timeString) };
    this.conditionChanged.emit({condition: 'scheduled-date', newValue});
  }

  applyOperator(newValue) {
    this.selectedOperator = newValue.label;
    this.conditionChanged.emit({condition: 'operator', newValue});
  }

  applyAlertMethod(newValue) {
    this.selectedAlertMethod = newValue;

    let phone = this.auth.getUser().phone;

    if (newValue == 'SMS text message' || newValue == 'Slack') {

      if (newValue == 'SMS text message') {
        if (!phone) {
          this.selectedAlertMethod = 'Email';
          this.conditionChanged.emit({condition: 'alertMethod', newValue: "Email"});
          this.alertify.error("There is no Mobile in your profile");
        }
      }
      else {
        this.removeVendorFromRecipients();

        if (this.selectedAlertRecipient === 'Vendor')
          this.selectedAlertRecipient = 'Select user';
      }

    } else {
      this.updateRecipientsBasedOnScope();
    }

    this.handleSelectedRecipients();
    this.conditionChanged.emit({condition: 'alertMethod', newValue});
  }

  applyAlertRecipient(recipient, selected, emit = true) {
    let canAdd = true;

    const exist = this.listRecipients.find(x => x.selected && x.value === selected?.label);
    if (exist) {
      this.alertify.error("Recipient already added.");
      canAdd = false;
      recipient.value = 'Select user';
      recipient.type = null;
      recipient.selected = false;
    }

    if (canAdd)  {
      recipient.value = selected?.label.trim();
      recipient.selected = true;
      this.handleSelectedRecipients(emit);
    }

    this.handleAlertMethods();
    this.handleSlackUsername();
  }

  getMethodTooltip(method) {
    let users:any = [];
    this.listRecipients.forEach(recipient => {
      if (recipient?.selected) {
        let value = recipient.value === 'Project Manager' ? 'PM' : recipient.value;
        let arr = this._projectTeam.filter(x => x.projectRole === value).map(x => x.name);
        if (arr.length > 0) users.push(arr);
      }
     });
    const message = users.join(', ') + ' has not added a Slack username to their account';
    return (method === 'Slack' && this.anyWithoutSlackUsername) ? message : '';
  }

  handleSlackUsername() {
    this.anyWithoutSlackUsername = false;

    this.listRecipients.forEach(recipient => {
      if (recipient?.selected) {
        if (recipient.value === 'Me' && this._projectTeam.filter(x => x.projectRole === 'Me').some(x => !x.slackUsername)) {
          this.anyWithoutSlackUsername = true;
          return;
        }
        if (recipient.value === 'Project Manager' && this._projectTeam.filter(x => x.projectRole === 'PM').some(x => !x.slackUsername)) {
          this.anyWithoutSlackUsername = true;
          return;
        }
        if (recipient.value === 'Secondary PM' && this._projectTeam.filter(x => x.projectRole === 'Secondary PM').some(x => !x.slackUsername)) {
          this.anyWithoutSlackUsername = true;
          return;
        }
      }
    });
    if (this.anyWithoutSlackUsername && this.selectedAlertMethod === 'Slack') {
      this.selectedAlertMethod = 'Select method';
    }
  }

  handleAlertMethods() {
    const vendorSelected = this.listRecipients.some(x => x.selected && x.value === 'Vendor');
    const anyPMSelected = this.listRecipients.some(x => x.selected && (x.value === 'Project Manager' || x.value === 'Secondary PM'));
    const anyEmailSelected = this.listRecipients.some(x => x.selected && x.value.includes('@'));

    if (vendorSelected) {
      this.alertMethods.splice(1); // Remove Slack and SMS options
      this.selectedAlertMethod = 'Email';
      this.conditionChanged.emit({condition: 'alertMethod', newValue: "Email"});
    }
    else if (!vendorSelected && anyPMSelected) {
      this.conditionChanged.emit({condition: 'alertMethod', newValue: "Email"});
      this.alertMethods = ['Email', 'Slack'];
      if (!this.alertMethods.includes(this.selectedAlertMethod))
        this.selectedAlertMethod = 'Select method';
    }
    else {
      this.alertMethods = ['Email', 'SMS text message', 'Slack'];
    }

    if (anyEmailSelected) {
      this.selectedAlertMethod = 'Email';
      this.alertMethods = ['Email'];
      this.conditionChanged.emit({condition: 'alertMethod', newValue: "Email"});
    }
  }

  handleSelectedRecipients(emit = true) {
    this.canAddNewRecipient = this.listRecipients.filter(x => !x?.selected).length === 0;
    const newValue = this.listRecipients.filter(x => x?.selected).map(x => x?.value).join(',');
    if (emit) this.conditionChanged.emit({condition: 'alertRecipient', newValue, canAddRecipient: this.canAddNewRecipient});
  }

  applyTaskAlertRecipient(newValue) {
    this.selectedTaskAlertRecipient = newValue;

    if (newValue == 'Assigned To') {
      this.selectedAlertMethod = 'Email';
      this.conditionChanged.emit({condition: 'alertMethod', newValue: "Email"});
      this.alertMethods = ['Email', 'Slack'];
    } else if (newValue == 'PM') {
      this.selectedAlertMethod = 'Email';
      this.conditionChanged.emit({condition: 'alertMethod', newValue: "Email"});
      this.alertMethods = ['Email', 'Slack'];
    } else {
      this.alertMethods = ['Email', 'SMS text message', 'Slack'];
    }

    this.conditionChanged.emit({condition: 'alertRecipient', newValue});
  }

  handleFields(type) {
    if (type === 'number') {
      this.applyMetricNumber();
    }
    if (type === 'time' || type === 'date') {
      this.applyScheduledDate();
    }
  }

  checkEmail(event) {
    this.newRecipient = event.target.value;
    this.emailValidator.setValue(this.newRecipient.trim());
  }

  addNewRecipientEmail(index) {
    const dropdown = this.listRecipients[index];
    const email = this.newRecipient.trim();

    if (!email) {
      this.alertify.error("Please enter email.");
      return;
    }

    const exist = this.listRecipients.some(item => item?.selected && item?.value.trim() === email);
    if (exist) {
      this.alertify.error("E-mail already registered.");
      this.newRecipient = '';
      return;
    }

    this.alertMethods = ['Email'];
    this.selectedAlertMethod = 'Email';

    dropdown.options.push({ label: email });
    dropdown.value = email;
    dropdown.type = 'email';
    dropdown.selected = true;

    this.conditionChanged.emit({condition: 'alertMethod', newValue: "Email"});
    this.handleSelectedRecipients();
    this.newRecipient = '';
    this.addingEmail = false;
  }

  addNewRecipient() {

    const existingRecipients = new Set(
      this.listRecipients
        .filter(recipient => recipient.selected && recipient.value !== 'Select user')
        .map(recipient => recipient.value)
    );

    const newOptions = (this.selectedScope === 'Project' || this.selectedScope === 'Select metric type')
      ? [{ label: 'Me' }, { label: 'Project Manager' }, { label: 'Secondary PM' }]
      : [{ label: 'Me' }, { label: 'Project Manager' }, { label: 'Secondary PM' }, { label: 'Vendor' }];

    const filteredOptions = newOptions.filter(option => !existingRecipients.has(option.label));

    // Add new recipient with filtered options
    this.listRecipients.push({
      value: 'Select user',
      type: null,
      selected: false,
      options: [...filteredOptions],
    });

    this.canAddNewRecipient = false;
  }

  removeNewRecipient(index) {
    const removedValue = this.listRecipients[index]?.value !== 'Select user' ? this.listRecipients[index].value : null;
    this.listRecipients.splice(index, 1);

    if (this.listRecipients.length === 0) {
      this.addNewRecipient();
      this.selectedAlertMethod = 'Select method';
    }
    else if (removedValue) {
      this.listRecipients.forEach(recipient => {
        if (!recipient.options.some(option => option.label === removedValue)) {
          recipient.options.push({ label: removedValue });
        }
      });
    }
    this.handleAlertMethods();
    this.handleSlackUsername();
    this.handleSelectedRecipients();
  }

  updateRecipientsBasedOnScope() {
    const newOptions = (this.selectedScope === 'Project' || this.selectedScope === 'Select metric type')
      ? [{ label: 'Me' }, { label: 'Project Manager' }, { label: 'Secondary PM' }]
      : [{ label: 'Me' }, { label: 'Project Manager' }, { label: 'Secondary PM' }, { label: 'Vendor' }];

    console.log(this.listRecipients);

    // Update the options for each dropdown
    this.listRecipients.forEach((recipient) => {
      if (!recipient?.selected) {
        recipient.options = [...newOptions];
        if (!newOptions.some((option) => option.label === recipient.value)) {
          recipient.type = null;
          recipient.value = 'Select user';
          recipient.selected = false;
        }
      }
    });
  }

  removeVendorFromRecipients() {
    this.listRecipients.forEach((recipient) => {
      const index = recipient.options.findIndex((x: any) => x.label === 'Vendor');
      if (index !== -1) {
        recipient.options.splice(index, 1);
      }
    });
  }

  cancelRecipientEmail() {
    this.addingEmail = false;
    this.newRecipient = '';
  }

  isTimepickerValid(event: any): void {
    if (!event && this.selectedScheduledTime.label !== 'Time') {
      this.alertify.error("Enter valid number");
      this.conditionChanged.emit({condition: 'scheduled-date', newValue: { value: null }});
    }
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const clickedElement = event.target as HTMLElement;
    if (!clickedElement.classList.contains('clickable')) {
      const editingDiv = this.elRef.nativeElement.querySelector('.grid-alert');
      const isClickInside = editingDiv && editingDiv.contains(clickedElement);
      if (!isClickInside) {
        this.listRecipients = this.listRecipients.filter(x => x.selected);
        this.handleSelectedRecipients();
      }
    }
  }

}
